.list {
    .list-container {
        &.min-height{
            .ms-DetailsList-contentWrapper{
                min-height: calc(100vh - 220px);
                max-height: calc(100vh - 220px);
                overflow-y: visible;
            }
        }
    }
    .no-records {
        font-size: 14px;
        text-align: center;
    }

    .pagination {
        text-align: right;
        margin: 0 10px 0 0;
        border-top: 1px solid rgb(237, 235, 233);
        border-bottom: 1px solid rgb(237, 235, 233);
    }
}