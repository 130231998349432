@import "../../../../assets/scss/theme/variable";

.tile-list{
    .tile-heading {
        font-size: 18px;
        padding-bottom: 10px;
        border-bottom: 1px solid $teams;
        width: 95%;
        font-weight: bold;
        margin-top: 10px;
    }
    .tiles-list {
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
    }
    .tile{
        cursor: pointer;
        border: 2px solid $oneDrive;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 20px;
        box-shadow: 1px 4px 6px 2px $box-shadow;
        .title{
            padding: 10px;
            text-align: center;
            border-radius: 5px;
        }
        .count {
            background-color: $oneDrive-20;
            padding: 7px;
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            border-radius: 0px 0px 5px 5px;
        }
    }
}