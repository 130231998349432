@import "../../../assets/scss/theme/variable";

.top-nav-bar {
    &.container {
        display: flex;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        box-shadow: 1px 3px 3px 0px #ccc;
        background-color: $white;

        .user-actions {
            cursor: pointer;
            .ms-Callout{
                right: -9px !important;
                top: 5px !important;
                left: auto !important;
            }
        }
    }
}