@import "./theme/variable";
@import "./index.scss";

.page-content {
  padding: 40px 0 4px;
  height: calc(100vh - 61px);
}

.errorBox {
  margin: 10px 0;
}

.menu-page {
  padding: 10px 0;
  width: 100%;
  overflow: auto;
}

.box {
  &.sm {
    width: 300px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;

    &.m-top-20 {
      margin-top: 20px;
    }
  }
}

.ucwords {
  text-transform: capitalize;
}

.btn-group {
  display: flex;
  justify-content: space-between;

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  .ms-Button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.Toastify__toast-container {
  margin-top: 35px;

  .Toastify__toast-body {
    font-family: 'Segoe UI', sans-serif;
    font-size: 14px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.admin-forms {
  margin-top: 20px;
}

.error {
  color: $access;
}

.bold {
  font-weight: bold;
}

.search-select-container {
  display: flex;
  flex-direction: column;
}

.data-row {
  margin-top: 5px;
  display: flex;

  .label {
    font-weight: bold;
    margin-right: 10px;
  }
}

@media only print {
  .menu-page {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }

  #client_report_print {
    width: auto;
    height: auto;
    overflow: visible;
  }
}

.stock-row {
  border-bottom: 1px solid #edebe9;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form-actions {
  button {
    margin-right: 10px;
  }
}

.modal-container {
  &.large {
    .ms-Dialog-main {
      width: 700px !important;
      max-width: 700px !important;
    }
  }
}