.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0 0;
    .page-title {
        display: flex;
        align-items: center;
        .page-title-text {
            font-weight: bold;
            margin-right: 20px;
            font-size: 20px;
        }
    }
    .actions{
        align-self: flex-start;
        .captalise{
            text-transform: capitalize;
        }
    }
}