@media screen,
print {
    .client-report {
        margin-top: 50px;

        table {
            border-collapse: collapse;
            margin-top: 10px;

            tr {

                th,
                td {
                    border: 1px solid black;
                    padding: 10px;
                }
            }
        }
    }
}

@media print {
    @page {
        margin: 0;
    }

    .no-print {
        display: none;
    }

    .client-report {
        margin-top: 0px;
        margin: auto;
        font-size: 14px;

        th,
        td {
            font-size: 12px;
        }
    }
}

.capitalise {
    text-transform: capitalize;
}