@import "../../../assets/scss/theme/variable";

.app-loader {
    &.container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $loader-bg-color;
        z-index: 10000000;
    }
}