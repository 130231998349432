$black:#000;
$white:#fff;
$loader-bg-color: rgba(255,255,255,0.5);
$details-bg-color: rgb(237, 235, 233);
$box-shadow: #ccc;
$primary: #0078d4;
$oneDrive:#0078d4;
$oneDrive-20: #c7e0f4;
$teams:#6264a7;
$teams-20:#BDBDE6;
$access: #a4373a;